.grid{
  max-height: 16.25rem;
  border-radius: 0.75rem;
  overflow-y: auto;
  scrollbar-width: thin; /* Asegura que la scrollbar sea delgada */
}

/* Quitar las flechas en WebKit Browsers (Chrome, Safari, Edge) */
.grid::-webkit-scrollbar-button {
  display: none; /* Oculta las flechas */
}

/* Estilos para la scrollbar en navegadores basados en WebKit (Chrome, Safari, Edge) */
.grid::-webkit-scrollbar {
  width: 0.5rem;
}

.grid::-webkit-scrollbar-track {
  background: #ffffff; /* Color del fondo de la scrollbar */
}

.grid::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* Color del "thumb" */
  border-radius: 0.5rem; /* Bordes redondeados */
}

.grid::-webkit-scrollbar-thumb:hover {
  background-color: #f1f1f1; /* Color del thumb cuando pasa el mouse */
}

/* Estilos para Firefox */
.grid {
  scrollbar-width: thin;
  scrollbar-color: #BDBDBD #ffffff;
}