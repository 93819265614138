.animate__animated.animate__fadeIn {
  --animate-duration: 500ms;
}

.overflow-y {
  scroll-behavior: smooth;
}

.overflow-y::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
.overflow-y::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.overflow-y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.overflow-y::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
.overflow-y::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

#ARSUSDSwitch .chakra-switch__track{
  background: #64247E;
}

#ARSUSDSwitch .chakra-switch__track[data-checked]{
  background: #4CAF50;
}
